// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  baseUrl: 'https://cirrus-api.uat.msl.co.uk',
  googleMapsAPIKey: 'AIzaSyAuNQSGcmPYPG4rKO3D4eaB7Gc3qtyy4iA',
  apiPrefix: '/api',
  clientSecret: 'aaaaaaaa-dfb2-4cf8-8151-d85b41cd7be1',
  activityTimerHubUrl: 'http://localhost:8080',
  buildLabel: '3.6.0.0',
  telephonyAutoReconnect: false,
  baseUrlForRaisingDefect: 'https://cirrus-api.uat.msl.co.uk',
  apiPrefixForRaisingDefect: '/api',
  projectKeyForRaisingDefect: '310A7F1C-5C1C-4ACC-B6AE-2374F8E35012',
  tinyMceAPIKey: '22rkbk242pq16is8zu150l3irvgnq0wtd6ehzbwwia0dstva',
  name: 'DEV',
};

